<template>
  <a-card>
    <a-form-model
        ref="searchForm"
        :model="searchForm"
        layout="inline"
    >
      <div :class="openForm ? 'openFormDiv' : 'formDiv'">
        <a-row>
          <a-col :span="6">
            <a-form-model-item label="项目名称">
              <a-input
                  v-model.trim="searchForm.projectName"
                  allow-clear
                  placeholder="请输入"
                  style="width: 170px;"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="状态">
              <a-select
                  v-model="searchForm.projectStatus"
                  style="width: 200px;"
                  placeholder="请选择"
              >
                <a-select-option
                    key=""
                >
                  全部
                </a-select-option>
                <a-select-option
                    v-for="item in statusList"
                    :key="item.value"
                    :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="品牌">
              <a-select
                  v-model="searchForm.principalId"
                  :default-active-first-option="false"
                  option-filter-prop="children"
                  show-search
                  placeholder="请输入"
                  style="width: 200px;"
                  @change="handlePrincipalChange"
              >
                <a-select-option key="">全部</a-select-option>
                <a-select-option
                    v-for="item in principalList"
                    :key="item.value"
                    :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="经销商">
              <a-input
                  v-model.trim="searchForm.dealerName"
                  allow-clear
                  placeholder="请输入"
                  style="width: 170px;"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="是否对客">
              <a-select
                  v-model="searchForm.dataUsageScope"
                  style="width: 170px;"
                  placeholder="请选择"
              >
              <a-select-option key="">全部</a-select-option>
              <a-select-option key="1">是</a-select-option>
              <a-select-option key="0">否</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>
      <a-row>
        <a-col class="flex-end">
          <a-button
              class="mr-20"
              @click="openForm = !openForm"
          >
            {{ !openForm ? '展开搜索条件' : '收起搜索条件' }}
          </a-button>
          <a-button
              class="mr-20"
              type="primary"
              icon="search"
              @click="bindSearch"
          >
            查询
          </a-button>
          <a-button
              class="mr-20"
              icon="redo"
              @click="resetSearch"
          >
            重置
          </a-button>
          <a-button
              class="mr-20"
              type="primary"
              icon="plus"
              @click="createProject"
          >
            新建项目
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="data-table">
      <a-table
          :loading="loading"
          :columns="columns"
          :data-source="list"
          :row-key="(record, index) => index"
          :pagination="false"
          :scroll="{ x: 1000}"
      >
        <div slot="projectInfo" slot-scope="text, record">
          <div class="detail-item">
            <span class="detail-item__label">名称：</span>
            <span class="detail-item__value">{{ record.projectName || "-" }}</span>
          </div>
          <div class="detail-item">
            <span class="detail-item__label">品牌：</span>
            <span class="detail-item__value">{{ record.principalName || "-" }}</span>
          </div>
          <div class="detail-item">
            <span class="detail-item__label">负责人：</span>
            <span class="detail-item__value">{{ record.ownerName || "-" }}</span>
          </div>
        </div>
        <div slot="Time" slot-scope="text, record">
          <div>{{ record.startDate }} - {{ record.endDate }}</div>
        </div>
        <div slot="status" slot-scope="text, record">
          <div>{{
              record.projectStatus == 1 ? "进行中" : record.projectStatus == 2 ? "未开始" : record.projectStatus == 3 ? "已结束" : ""
            }}
          </div>
        </div>
        <div slot="activityTime" slot-scope="text, record">
          <div class="detail-item">
            <span class="detail-item__label">更新时间：</span>
            <span class="detail-item__value">{{ record.mtime || "-" }}</span>
          </div>
          <div class="detail-item">
            <span class="detail-item__label">创建时间：</span>
            <span class="detail-item__value">{{ record.ctime || "-" }}</span>
          </div>
          <div class="detail-item">
            <span class="detail-item__label">创建人：</span>
            <span class="detail-item__value">{{ record.creatorOauthName || "-" }}</span>
          </div>
        </div>
        <div slot="action" slot-scope="text, record">
          <router-link
              :to="{ name: 'projectDetail', query: { id: record.id } }"
              target="_blank"
          >
            查看详情
          </router-link>
        </div>
      </a-table>
      <base-pagination
          :current-page="pagination.current"
          :page-size="pagination.pageSize"
          :total="pagination.total"
          @onChange="handlePaginationChange"
          @onShowSizeChange="handlePaginationChange"
      />
    </div>

    <projectModal
        :visible="projectModal_visible"
        :title="projectModal_title"
        :principal-list="principalList"
        @handleCancel="handleCancel"
    />
  </a-card>
</template>

<script>
import baseApi from "@/api/base";
import projectModal from "./components/projectModal";
import _API from "@/api/projectManagement";
import { isEmpty } from "lodash-es";

export default {
  name: "projectList",
  data() {
    return {
      searchForm: {
        principalId: "",
        projectStatus: "",
      },
      statusList: [
        // {
        //   value: null,
        //   label: "全部"
        // },
        {
          value: 1,
          label: "进行中"
        },
        {
          value: 2,
          label: "未开始"
        },
        {
          value: 3,
          label: "已结束"
        },
      ],
      principalList: [],
      loading: false,
      columns: [
        {
          title: "项目基础信息",
          dataIndex: "",
          width: 220,
          scopedSlots: {customRender: "projectInfo"}
        },
        {
          title: "是否对客",
          dataIndex: "dataUsageScope",
          width: 150,
          customRender: (text, row, index) => {
            return text == 1 ? '是' : '否'
          }
        },
        {
          title: "起止时间",
          scopedSlots: {customRender: "Time"},
          width: 200,
        },
        {
          title: "状态",
          dataIndex: "",
          width: 120,
          scopedSlots: {customRender: "status"}
        },
        {
          title: "更新信息",
          dataIndex: "",
          width: 250,
          scopedSlots: {customRender: "activityTime"}
        },
        {
          title: "操作",
          key: "action",
          width: 130,
          // fixed: "right",
          scopedSlots: {customRender: "action"}
        }
      ],
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      projectModal_visible: false,
      projectModal_title: "",
      openForm: false,
    };
  },
  components: {projectModal},
  mounted() {
    this.fetchPrincipalList();
    this.getProjectList();
    this.openForm = false
  },
  methods: {
    getProjectList() {
      this.loading = true;
      let params = this.searchForm;
      params.page = this.pagination.current;
      params.size = this.pagination.pageSize;
      _API.getProjectList(params).then((res) => {
        if(res.code == 200) {
          this.list = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.list = [];
          this.pagination.total = 0;
          this.$message.error(res.message || "获取列表数据失败");
        }
        this.loading = false;
      });
    },
    // 获取主体列表
    async fetchPrincipalList() {
      try {
        const {code, data, message} = await baseApi.fetchPrincipalList();

        if(code === 200) {
          this.principalList = [
            ...data.map(item => {
              return {label: item.principal_name, value: item.id};
            })
          ];
        } else {
          this.$message.error(message);
          this.principalList = [];
        }
      } catch(e) {
        console.error(e);
        this.principalList = [];
      }
    },
    bindSearch() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getProjectList();
    },
    resetSearch() {
      this.pagination.pageSize = 10;
      this.pagination.current = 1;
      this.searchForm = {
        principalId: "",
        projectStatus: ""
      };
      this.getProjectList();
    },
    createProject() {
      this.projectModal_visible = true;
      this.projectModal_title = "新建项目";
    },
    handleCancel() {
      this.projectModal_visible = false;
      this.getProjectList();
    },
    handlePrincipalChange() {
    },
    handlePaginationChange(page, size) {
      // console.log(page, size);
      this.pagination.current = page;
      this.pagination.pageSize = size;
      this.getProjectList();
    },
  }
};
</script>

<style scoped lang="less">
.mr-20 {
  margin-right: 20px;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
}


.detail-item {
  display: flex;
  font-weight: bold;

  &__value {
    flex: 1;
    font-weight: 400;
  }
}

.formDiv {
  height: 40px;
  overflow: hidden;
  transition: height .5s;
}

.openFormDiv {
  height: 80px;
  transition: height .5s;
}
</style>
